import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MapResponseDTO } from 'src/app/data/dto/EventDTO';
import { UseCase } from '../../base/UserCase';
import { EventRepository } from '../../repositories/event/EventRepository';

@Injectable({
    providedIn: 'root'
})

export class MapEventUseCase implements UseCase<number, MapResponseDTO>{
    constructor(
        private eventRepository: EventRepository,
    ) { }
    execute(id: number): Observable<MapResponseDTO> {
        return this.eventRepository.mapOfEvent(id);
    }
}
