import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsersRepository } from '../../repositories/user/UsersRepository';
import { OnlyUsersRequestDTO, OnlyUsersResponseDTO } from 'src/app/data/dto/UsersDTO';

@Injectable({
    providedIn: 'root'
})

export class OnlyUsersUseCase implements UseCase<OnlyUsersRequestDTO, OnlyUsersResponseDTO>{
    constructor(
        private usersRepository: UsersRepository,
    ) { }

    execute(request: OnlyUsersRequestDTO): Observable<OnlyUsersResponseDTO> {
        return this.usersRepository.onlyUsers(request);
    }
}
