import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EnclosuresRepository } from '../../repositories/enclosures/EnclosuresRepository';
import { RecintosRequestDTO, RecintosResponseDTO } from 'src/app/data/dto/EnclosuresDTO';

@Injectable({
    providedIn: 'root'
})

export class ListEnclosuresUseCase implements UseCase<RecintosRequestDTO, RecintosResponseDTO> {
    constructor(
        private enclosuresRepository: EnclosuresRepository,
    ) { }

    execute(request: RecintosRequestDTO ): Observable<RecintosResponseDTO> {
        return this.enclosuresRepository.enclosures(request);
    }
}
