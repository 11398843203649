import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultSumaryPaymentDTO } from 'src/app/data/dto/BuyTicketsDTO';
import { UseCase } from '../../base/UserCase';
import { BuyTicketRepository } from '../../repositories/buyTickets/BuyTicketRepository';

@Injectable({
    providedIn: 'root'
})

export class SumaryPaymentUserUseCase implements UseCase<number, ResultSumaryPaymentDTO>{
    constructor(
        private buyTicketRepository: BuyTicketRepository,
    ) { }

    execute(id: number): Observable<ResultSumaryPaymentDTO> {
        return this.buyTicketRepository.getSumaryPayment(id);
    }
}
