import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AvailablePlacesRequestDTO,
    AvailablePlacesResponseDTO,
} from 'src/app/data/dto/BuyTicketsDTO';
import { UseCase } from '../../base/UserCase';
import { BuyTicketRepository } from '../../repositories/buyTickets/BuyTicketRepository';

@Injectable({
    providedIn: 'root'
})

export class AvailablePlaceUseCase implements UseCase<AvailablePlacesRequestDTO, AvailablePlacesResponseDTO>{
    constructor(
        private buyTicketRepository: BuyTicketRepository,
    ) { }

    execute(request: AvailablePlacesRequestDTO): Observable<AvailablePlacesResponseDTO> {
        return this.buyTicketRepository.availablePlaces(request);
    }
}
