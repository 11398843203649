import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EventTypeRepository } from 'src/app/core/repositories/eventType/EventTypeRepository';
import {
    EventTypeResponseDTO,
} from 'src/app/data/dto/EventTypeDTO';

@Injectable({
    providedIn: 'root'
})

export class ListEventTypeUseCase implements UseCase<string, EventTypeResponseDTO> {
    constructor(
        private eventTypeRepository: EventTypeRepository,
    ) { }

    execute(): Observable<EventTypeResponseDTO> {
        return this.eventTypeRepository.listEventType();
    }
}
