import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../../base/UserCase';
import { QRPrepagoRepository } from '../../repositories/qrprepago/QRPrepagoRepository';

@Injectable({
    providedIn: 'root'
})

export class AccountStatusPrepaidUseCase implements UseCase<any, any>{
    constructor(
        private qRPrepaidRepository: QRPrepagoRepository,
    ) { }

    execute(request: any): Observable<any> {
        return this.qRPrepaidRepository.accountStatus(request);
    }
}
