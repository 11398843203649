import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../../base/UserCase';
import { UsersRepository } from '../../repositories/user/UsersRepository';
import { ListEventsOfUSerDTO, UserIdDTO } from 'src/app/data/dto/UsersDTO';

@Injectable({
    providedIn: 'root'
})

export class ListEventsOfUserUseCase implements UseCase<UserIdDTO, ListEventsOfUSerDTO>{
    constructor(
        private usersRepository: UsersRepository,
    ) { }

    execute(id: UserIdDTO): Observable<ListEventsOfUSerDTO> {
        return this.usersRepository.getAllPurchaseTicket(id);
    }
}
