import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { QRPrepagoRepository } from '../../repositories/qrprepago/QRPrepagoRepository';
import { GetAllTypeOfPrepaidRequestDTO, GetAllTypeOfPrepaidResponseDTO } from 'src/app/data/dto/QRPrepaidDTO';

@Injectable({
    providedIn: 'root'
})

export class GetAllTypeOfPrepaidUseCase implements UseCase<GetAllTypeOfPrepaidRequestDTO, GetAllTypeOfPrepaidResponseDTO>{
    constructor(
        private qRPrepagoRepository: QRPrepagoRepository,
    ) { }

    execute(request: GetAllTypeOfPrepaidRequestDTO): Observable<GetAllTypeOfPrepaidResponseDTO> {
        return this.qRPrepagoRepository.getAllTypeOfPrepaid(request);
    }
}
