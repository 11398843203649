import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersResponseDTO } from 'src/app/data/dto/UsersDTO';
import { UseCase } from '../../base/UserCase';
import { UsersRepository } from '../../repositories/user/UsersRepository';

@Injectable({
    providedIn: 'root'
})

export class GetUserByIdUseCase implements UseCase<number, UsersResponseDTO> {
    constructor(
        private usersRepository: UsersRepository,
    ) { }

    execute(id: number): Observable<UsersResponseDTO> {
        return this.usersRepository.getUserById(id);
    }
}
