import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EnclosuresRepository } from 'src/app/core/repositories/enclosures/EnclosuresRepository';
import {
    OnlyEnclosureDTO,
} from 'src/app/data/dto/EnclosuresDTO';

@Injectable({
    providedIn: 'root'
})

export class GetAllOnlyEnclosureUseCase implements UseCase<string, OnlyEnclosureDTO> {
    constructor(
        private enclosuresRepository: EnclosuresRepository,
    ) { }

    execute(): Observable<OnlyEnclosureDTO> {
        return this.enclosuresRepository.onlyEnclosures();
    }
}
