import { Injectable } from '@angular/core';
import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { AddUserDTO, UsersResponseResgisterDTO } from 'src/app/data/dto/UsersDTO';
import { UsersRepository } from '../../repositories/user/UsersRepository';

@Injectable({
    providedIn: 'root'
})

export class AddUserUseCase implements UseCase<AddUserDTO, UsersResponseResgisterDTO> {
    constructor(
        private usersRepository: UsersRepository,
    ) { }

    execute(params: AddUserDTO): Observable<UsersResponseResgisterDTO> {
        return this.usersRepository.addUser(params);
    }
}
