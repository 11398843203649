import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../../base/UserCase';
import { QRPrepagoRepository } from '../../repositories/qrprepago/QRPrepagoRepository';

@Injectable({
    providedIn: 'root'
})

export class MigrateUserUseCase implements UseCase<{ email: string }, any> {
    constructor(
        private qRPrepaidRepository: QRPrepagoRepository,
    ) { }

    execute(request: { email: string }): Observable<any> {
        return this.qRPrepaidRepository.migrateUser(request);
    }
}
