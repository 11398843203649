import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DiscountCodeRepository } from 'src/app/core/repositories/discountCode/DiscountCodeRepository';
import {
    DiscountCodeResponseDTO,
} from 'src/app/data/dto/DiscountCodeDTO';

@Injectable({
    providedIn: 'root'
})

export class ListDiscountCodeUseCase implements UseCase<string, DiscountCodeResponseDTO> {
    constructor(
        private discountCodeRepository: DiscountCodeRepository,
    ) { }

    execute(): Observable<DiscountCodeResponseDTO> {
        return this.discountCodeRepository.listDiscountCode();
    }
}
