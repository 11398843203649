import { Injectable } from '@angular/core';
import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { CartRepository } from '../../repositories/cart/CartRepository';
import {
    EmptyCartRequestDTO,
    EmptyCartResponseDTO
} from 'src/app/data/dto/CartDTO';

@Injectable({
    providedIn: 'root'
})

export class EmptyCartUseCase implements UseCase<EmptyCartRequestDTO, EmptyCartResponseDTO> {
    constructor(
        private cartRepository: CartRepository,
    ) { }

    execute(request: EmptyCartRequestDTO): Observable<EmptyCartResponseDTO> {
        return this.cartRepository.emptyCart(request);
    }
}
