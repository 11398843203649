import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../../base/UserCase';
import { EventRepository } from '../../repositories/event/EventRepository';
import {
    EventsWithZoneResponseDTO,
    EventZoneDTO
} from 'src/app/data/dto/EventDTO';

@Injectable({
    providedIn: 'root'
})

export class EventParkingAreaUseCase implements UseCase<EventZoneDTO, EventsWithZoneResponseDTO>{
    constructor(
        private eventRepository: EventRepository,
    ) { }

    execute(id: EventZoneDTO): Observable<EventsWithZoneResponseDTO> {
        return this.eventRepository.eventWithAreas(id);
    }
}
