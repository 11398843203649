import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnlyEventsDTO } from 'src/app/data/dto/EventDTO';
import { UseCase } from '../../base/UserCase';
import { EventRepository } from '../../repositories/event/EventRepository';

@Injectable({
    providedIn: 'root'
})

export class GetAllOnlyEventsUseCase implements UseCase<number, OnlyEventsDTO> {
    constructor(
        private eventRepository: EventRepository,
    ) { }

    execute(id: number): Observable<OnlyEventsDTO> {
        return this.eventRepository.getOnlyEvents(id);
    }

}
