import { Injectable } from '@angular/core';
import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { EnclosuresRepository } from '../../repositories/enclosures/EnclosuresRepository';
import {
  ZoneByEventsRequestDTO,
  ZoneByEventsResponseDTO
} from 'src/app/data/dto/EnclosuresDTO';

@Injectable({
    providedIn: 'root'
})

export class ZoneByEventsUseCase implements UseCase<ZoneByEventsRequestDTO, ZoneByEventsResponseDTO>{
    constructor(
        private enclosuresRepository: EnclosuresRepository,
    ) { }

    execute(id: ZoneByEventsRequestDTO): Observable<ZoneByEventsResponseDTO> {
        return this.enclosuresRepository.zoneByEvents(id);
    }
}
