import { Injectable } from '@angular/core';
import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { CartRepository } from '../../repositories/cart/CartRepository';
import {
    DeleteCartRequestDTO,
    DeleteCartResponseDTO
} from 'src/app/data/dto/CartDTO';

@Injectable({
    providedIn: 'root'
})

export class DeleteCartUseCase implements UseCase<DeleteCartRequestDTO, DeleteCartResponseDTO> {
    constructor(
        private cartRepository: CartRepository,
    ) { }

    execute(request: DeleteCartRequestDTO): Observable<DeleteCartResponseDTO> {
        return this.cartRepository.deleteCart(request);
    }
}
