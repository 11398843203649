import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnlyParkingAreaDTO } from 'src/app/data/dto/ParkingAreaDTO';
import { UseCase } from '../../base/UserCase';
import { ParkingAreaRepository } from '../../repositories/parking-area/ParkingAreaRepository';

@Injectable({
    providedIn: 'root'
})

export class GetAllOnlyParkingAreaUseCase implements UseCase<number, OnlyParkingAreaDTO> {
    constructor(
        private parkingAreaRepository: ParkingAreaRepository,
    ) { }

    execute(id: number): Observable<OnlyParkingAreaDTO> {
        return this.parkingAreaRepository.getOnlyParkingArea(id);
    }

}
