import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../../base/UserCase';
import { QRPrepagoRepository } from '../../repositories/qrprepago/QRPrepagoRepository';

import {
  ListarTypesPrepaidRequestDTO,
  ListarTypesPrepaidResponseDTO
} from 'src/app/data/dto/QRPrepaidDTO';


@Injectable({
    providedIn: 'root'
})

export class ListarTypesPrepaidUseCase implements UseCase<any, any>{
    constructor(
        private qRPrepaidRepository: QRPrepagoRepository,
    ) { }

    execute(request: ListarTypesPrepaidRequestDTO): Observable<ListarTypesPrepaidResponseDTO> {
        return this.qRPrepaidRepository.listTypes(request);
    }
}
