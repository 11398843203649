import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CartRepository } from 'src/app/core/repositories/cart/CartRepository';
import {
    PayCartRequestDTO,
    PayCartResponseDTO,
} from 'src/app/data/dto/CartDTO';

@Injectable({
    providedIn: 'root'
})

export class PayCartUseCase implements UseCase<PayCartRequestDTO, PayCartResponseDTO> {
    constructor(
        private cartRepository: CartRepository,
    ) { }

    execute(request: PayCartRequestDTO): Observable<PayCartResponseDTO> {
        return this.cartRepository.payMyCart(request);
    }
}
